

















































































import Vue from 'vue';
const Ripple = require('vue-ripple-directive');
import moment from 'moment';
import axios from 'axios';
import { faCircle, faStar, faCaretUp } from '@fortawesome/free-solid-svg-icons';
interface EarthquakeData {
  dateTime: number;
  lat: number;
  lon: number;
  magnitude: number;
  depth: number;
}
interface EarthquakeYearEvents {
  year: number;
  events: Array<EarthquakeData>;
}
interface Position {
  lat: number;
  lng: number;
}

export default Vue.extend({
  data() {
    let eventMap = new Map<number, Array<EarthquakeData>>();
    let fields = [
      {
        key: 'dateTime',
        label: '地震時間',
        sortable: true,
        formatter: (v: number) => moment(v).format('y-MM-DD HH:mm:ss'),
      },
      {
        key: 'lon',
        label: '經度',
        sortable: true,
        formatter: (v: number) => v.toFixed(4),
      },
      {
        key: 'lat',
        label: '緯度',
        sortable: true,
        formatter: (v: number) => v.toFixed(4),
      },
      {
        key: 'magnitude',
        label: '規模',
        sortable: true,
        formatter: (v: number) => v.toFixed(1),
      },
      {
        key: 'depth',
        label: '深度',
        sortable: true,
        formatter: (v: number) => v.toFixed(1),
      },
    ];
    let year = moment().year();
    let stations = [
      {
        name: 'CBPV-B',
        lng: 120.392139,
        lat: 24.098523,
      },
      {
        name: 'CBPV-D',
        lng: 120.39126,
        lat: 24.097976,
      },
      {
        name: 'TCPP-E',
        lng: 120.4913219,
        lat: 24.2080094,
      },
    ];
    return {
      year,
      eventMap,
      fields,
      eventTitle: '',
      activeDateTime: 0,
      mapLoaded: false,
      stations,
    };
  },
  computed: {
    years(): Array<number> {
      return Array.from(this.eventMap.keys());
    },
    earthquakeEvents(): Array<EarthquakeData> {
      return this.eventMap.get(this.year) ?? Array<EarthquakeData>();
    },
    baseUrl(): string {
      return process.env.NODE_ENV === 'development'
        ? 'http://localhost:9000/'
        : '/';
    },
    mapCenter() {
      let lat = 23.974184149523335;
      let lng = 120.98011790489949;
      return { lat, lng };
    },
  },
  async mounted() {
    this.$gmapApiPromiseLazy().then(() => {
      this.mapLoaded = true;
    });

    await this.getEarthquakeEvents();
  },
  methods: {
    async getEarthquakeEvents() {
      try {
        let res = await axios.get('/EarthquakeEvents');
        if (res.status === 200) {
          let eventMap = new Map<number, Array<EarthquakeData>>();
          for (let entry of res.data) {
            let yearEvent = entry as EarthquakeYearEvents;
            eventMap.set(yearEvent.year, yearEvent.events);
          }
          this.eventMap = eventMap;

          let years = Array.from(this.eventMap.keys());

          if (years.length !== 0) this.year = Math.max(...years);
        }
      } catch (err) {
        console.error(err);
      }
    },
    onRowSelected(events: Array<EarthquakeData>) {
      if (events.length !== 0) {
        this.eventTitle = `${moment(events[0].dateTime).format(
          'lll',
        )} - 地震時間`;
        this.activeDateTime = events[0].dateTime;
        this.$bvModal.show('event-modal');
      }
    },
    getReportImgUrl(): string {
      return `${this.baseUrl}EarthquakeReport?dateTime=${this.activeDateTime}`;
    },
    getBImgUrl(): string {
      return `${this.baseUrl}EarthquakeBImg?dateTime=${this.activeDateTime}`;
    },
    getDImgUrl(): string {
      return `${this.baseUrl}EarthquakeDImg?dateTime=${this.activeDateTime}`;
    },
    getEImgUrl(): string {
      return `${this.baseUrl}EarthquakeEImg?dateTime=${this.activeDateTime}`;
    },
    getEventPos(evt: EarthquakeData): Position {
      let lat = evt.lat;
      let lng = evt.lon;
      return { lat, lng };
    },
    getEventTitle(evt: EarthquakeData): string {
      return moment(evt.dateTime).format('y年MM月DD日 HH:mm:ss');
    },
    getEarthQuakeIcon(data: EarthquakeData): any {
      if (data.dateTime === this.activeDateTime)
        return {
          path: faStar.icon[4] as string,
          fillColor: '#ff0000',
          fillOpacity: 1,
          anchor: new google.maps.Point(
            faStar.icon[0] / 2, // width
            faStar.icon[1], // height
          ),
          strokeWeight: 1,
          strokeColor: '#ffffff',
          scale: 0.03,
        };

      return {
        path: faCircle.icon[4] as string,
        fillColor: '#008f00',
        fillOpacity: 1,
        anchor: new google.maps.Point(
          faCircle.icon[0] / 2, // width
          faCircle.icon[1], // height
        ),
        strokeWeight: 1,
        strokeColor: '#ffffff',
        scale: 0.015,
      };
    },
    getStationIcon(): any {
      return {
        path: faCaretUp.icon[4] as string,
        fillColor: '#ff0000',
        fillOpacity: 1,
        anchor: new google.maps.Point(
          faStar.icon[0] / 2, // width
          faStar.icon[1], // height
        ),
        strokeWeight: 1,
        strokeColor: '#ffffff',
        scale: 0.04,
      };
    },
    onIconClick(evt: EarthquakeData, index: number) {
      this.activeDateTime = evt.dateTime;
      this.$refs.earthquakeEvents.selectRow(index);
    },
  },
});
